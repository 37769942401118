module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3S6NFX813M","AW-951050257"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"781454775914533"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
